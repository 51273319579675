// src/data/timelineData.js

export const milestones = [
  {
    date: '2024-10',
    title: 'Development Started',
    description: [
      '• Building the model from the Intrusion detection evaluation dataset',
      '(CIC-IDS2017): https://www.unb.ca/cic/datasets/ids-2017.html',
    ],
    icon: '🏆',
  },
  {
    date: '2024-11',
    title: 'Registration',
    description: [
      '• LLC Formed (CyberSecurity AI)',
      '• Registered on Sam.Gov, Grants.gov (UEI: E43RTEYMDLN8)'
    ],
    icon: '🔍',
  },
];
