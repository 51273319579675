// src/components/Timeline.js

import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { milestones } from '../data/timelineData';

const Timeline = () => {
  // Get current date in 'YYYY-MM' format
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // getMonth() is 0-indexed
  const currentDateStr = `${currentYear}-${currentMonth}`;

  return (
    <div className="relative wrap overflow-hidden p-10 h-full">
      {/* Vertical line */}
      <div className="absolute border-opacity-10 border-primary h-full border left-1/2 transform -translate-x-1/2"></div>

      {milestones.map((milestone, index) => (
        <TimelineItem
          key={index}
          milestone={milestone}
          index={index}
          isCurrent={milestone.date === currentDateStr}
        />
      ))}
    </div>
  );
};

const TimelineItem = ({ milestone, index, isCurrent }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  // Determine side based on index
  const isLeft = index % 2 === 0;

  return (
    <motion.div
      ref={ref}
      className="mb-8 flex justify-between items-center w-full"
      initial={{ opacity: 0, x: isLeft ? -100 : 100 }}
      animate={inView ? { opacity: 1, x: 0 } : {}}
      transition={{ duration: 0.6, ease: 'easeOut' }}
    >
      {/* Content */}
      <div
        className={`order-1 w-5/12 ${
          isLeft ? 'text-right pr-8' : 'text-left pl-8'
        } ${isCurrent ? 'border-l-4 border-red-500 p-4 bg-red-50 dark:bg-red-900' : ''}`}
      >
        <p className="mb-3 text-lg font-medium text-primary dark:text-white">
          {formatDate(milestone.date)}
        </p>
        <h3 className={`mb-3 text-2xl font-bold ${isCurrent ? 'text-red-500 dark:text-white' : 'dark:text-white'}`}>
          {milestone.title}
        </h3>
        <p
          className={`leading-snug ${
            isCurrent
              ? 'text-red-500 dark:text-white'
              : 'text-gray-700 dark:text-white'
          }`}
        >
          {milestone.description.map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>

      {/* Dot */}
      <motion.div
        className={`z-20 flex items-center order-1 w-8 h-8 rounded-full shadow-xl ${
          isCurrent ? 'bg-red-500' : 'bg-primary'
        }`}
        animate={
          isCurrent
            ? {
                scale: [1, 1.2, 1],
                opacity: [1, 0.7, 1],
              }
            : {}
        }
        transition={
          isCurrent
            ? {
                repeat: Infinity,
                duration: 1.5,
                ease: 'easeInOut',
              }
            : {}
        }
      >
        <span className="mx-auto text-white">{milestone.icon}</span>
      </motion.div>

      {/* Spacer */}
      <div className={`order-1 w-5/12 ${isLeft ? 'pl-8' : 'pr-8'}`}></div>
    </motion.div>
  );
};


// Helper function to format 'YYYY-MM' to 'Month YYYY'
const formatDate = (dateStr) => {
  const [year, month] = dateStr.split('-');
  const date = new Date(year, month - 1);
  const options = { year: 'numeric', month: 'long' };
  return date.toLocaleDateString(undefined, options);
};

export default Timeline;
